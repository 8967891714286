exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academia-jsx": () => import("./../../../src/pages/academia.jsx" /* webpackChunkName: "component---src-pages-academia-jsx" */),
  "component---src-pages-canal-etica-jsx": () => import("./../../../src/pages/canal-etica.jsx" /* webpackChunkName: "component---src-pages-canal-etica-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-armaduras-jsx": () => import("./../../../src/pages/info-armaduras.jsx" /* webpackChunkName: "component---src-pages-info-armaduras-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-noticias-jsx": () => import("./../../../src/pages/noticias.jsx" /* webpackChunkName: "component---src-pages-noticias-jsx" */),
  "component---src-pages-politica-privacidad-jsx": () => import("./../../../src/pages/politica-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-privacidad-jsx" */),
  "component---src-pages-politica-sgi-jsx": () => import("./../../../src/pages/politica-sgi.jsx" /* webpackChunkName: "component---src-pages-politica-sgi-jsx" */),
  "component---src-pages-recursos-jsx": () => import("./../../../src/pages/recursos.jsx" /* webpackChunkName: "component---src-pages-recursos-jsx" */),
  "component---src-pages-stock-semanal-jsx": () => import("./../../../src/pages/stock-semanal.jsx" /* webpackChunkName: "component---src-pages-stock-semanal-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-terminos-condiciones-jsx": () => import("./../../../src/pages/terminos-condiciones.jsx" /* webpackChunkName: "component---src-pages-terminos-condiciones-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-conference-template-jsx": () => import("./../../../src/templates/conference-template.jsx" /* webpackChunkName: "component---src-templates-conference-template-jsx" */),
  "component---src-templates-episode-template-jsx": () => import("./../../../src/templates/episode-template.jsx" /* webpackChunkName: "component---src-templates-episode-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/product-template.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */),
  "component---src-templates-resources-template-jsx": () => import("./../../../src/templates/resources-template.jsx" /* webpackChunkName: "component---src-templates-resources-template-jsx" */),
  "component---src-templates-subcategory-template-jsx": () => import("./../../../src/templates/subcategory-template.jsx" /* webpackChunkName: "component---src-templates-subcategory-template-jsx" */)
}

